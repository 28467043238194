import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralServiceService } from 'src/app/general-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { ClipboardService } from 'ngx-clipboard';
declare var $: any;

// var dataOutput = new FormData();

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})

export class SettingComponent implements OnInit {
  mbln: boolean;
  fieldlist: any = [];
  displaytype: String = '';
  styleData: any = [];
  authMethod: String = '';
  height: number;
  width: number;
  authValidation: String = '';
  profileParameter: any = [];
  id: String = '';
  settingData: any;
  websitename: String = '';
  apikeyname: String = '';
  apistatus: string = "";
  userdomain: String = '';
  tmpchkboxarr: any = [];
  webpageurl = '';
  file: any;
  imgser: string = '';
  imgpath: string = '';
  combineurl: any;
  desc: string = 'Welcome';
  logobuffer: String = '';
  // sanitizer:any;
  imageurl: any;
  databaseURL: String = '';
  host: String = '';
  contacts: String = '';
  label: String = '';
  eformlist: any = [];
  efoemdata: any;
  verification: any = false;
  verifiername: any = '';
  verifiergrade: any = '';
  akcessid: any = '';
  domainid: any;
  adminlist: any = [];
  settingview: any = "hide";
  show: string;
  buttonName = 'Show';
  hide: any;
  hasnode: any;
  channelname: string;
  channeldata: string;
  channelNamedata: string;
  normal_details: String;
  detail_value: String;


  constructor(private _clipboardService: ClipboardService, private gs: GeneralServiceService, private ts: ToastrService, public sanitizer: DomSanitizer, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.imgser = environment.imgserverurl;

  }


  ngOnInit() {
    this.mbln = false
    this.getFields();
    this.getsingledata();
  }

  // copy(text: string) {
  //   this._clipboardService.copy(text)
  // }

  getFields() {
    this.spinner.show();
    this.gs.getfieldList().subscribe(data => { if (data['status']) { this.fieldlist = data['data']; this.spinner.hide(); } else { this.spinner.hide(); this.ts.error(data['message']); } }, error => { console.log(error); this.spinner.hide(); });
  }

  viewSetting(value) {
    this.show = value;
  }

  getsingledata() {
    this.spinner.show();

    this.gs.singledomaindata(this.id).subscribe(data => {
      // console.log("data151515151", data['data'].admin);
      this.efoemdata = data['data'].domainname;
      this.geteformData();
      if (data['status']) {

        this.settingData = data['data'];
        this.spinner.hide();
        this.websitename = data['data'].domainname;
        this.apikeyname = data['data'].apikey
        this.apistatus = data['data'].status;
        this.userdomain = data['data'].usedomain;
        if (data['data']['setting']) {
          this.displaytype = data['data']['setting'].displaytype;
          this.authMethod = data['data']['setting'].authomethod;
          if (this.authMethod == 'otp') { this.mbln = true; }

          this.authValidation = data['data']['setting'].validationmethod;
          this.height = data['data']['setting']['style'].height;
          this.width = data['data']['setting']['style'].width;
          this.profileParameter = data['data']['setting'].parameter;
          this.imgpath = data['data']['setting'].filename;
          // this.webpageurl = data['data']['setting'].webapgeurl;

          this.databaseURL = data['data']['setting'].databaseURL;
          this.host = data['data']['setting'].host;
          this.contacts = data['data']['setting'].contacts;
          this.label = data['data']['setting'].label;
          this.desc = data['data']['setting'].desc;
          this.combineurl = data['data']['setting'].filename;
          this.verification = data['data']['setting'].verification;
          this.verifiername = data['data']['setting'].verifiername;
          this.verifiergrade = data['data']['setting'].verifiergrade;


          this.normal_details = data['data']['account_setting'].normal_details;
          this.detail_value = data['data']['account_setting'].value;

          // let TYPED_ARRAY = new Uint8Array(this.portal.logo.data.data);
          //       const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
          //       let base64String = btoa(STRING_CHAR);
          //       this.imageurl = this.sanitizer.bypassSecurityTrustUrl('data:'+this.portal.logo.contentType+';base64, ' + base64String);

        }

        // console.log(" this.userdomain", this.userdomain);
        this.adminlist = data['data'].admin;
        // console.log(" this.combineurl2222222222222222222222222222", this.adminlist);
      } else { this.spinner.hide(); this.ts.error(data['message']); }
    }, error => { console.log(error); this.spinner.hide(); });
  }

  selectrdo(val) {
    this.authMethod = val;
    if (val == 'otp') {
      this.mbln = true;
    } else {
      this.mbln = false;
      this.authValidation = '';
    }
  }

  selectstyle(val) {
    this.displaytype = val;
  }


  fileChanged(e) {
    // this.file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      // convert image file to base64 string
      // @ts-ignore
      document.getElementById('imagepreview').src = reader.result;
      // preview.src = reader.result;
    }, false);

    reader.readAsDataURL(e.target.files[0]);
  }


  getImage(input) {
    $(input)[0].click();
  }

  chenal(val) {
    this.hasnode = val;

    if (this.hasnode == true) {
      let chenaldata = this.websitename.split("." || "@");
      this.channelname = chenaldata[0] + 'private'
      this.hasnode = true
    }
    else {
      this.hasnode == false
    }
  }

  // if (this.chenalstatus == 'true') {
  //   console.log("hiiiiiiiiii333333");
  //   let chenalmname = this.domain.split("@");

  //   let data = {
  //     chenalstatus: this.chenalstatus,
  //     domain: this.domain,
  //     channelName: chenalmname[0]+'private'
  //   }
  //   console.log("lllllllllll", data);

  //   // return data
  // }
  // console.log("hiiiiiiiiii5555555");

  // let data = {
  //   chenalstatus: this.chenalstatus,
  //   domain: this.domain,

  // }
  // // return data

  // console.log("data", data);


  savesetting() {
    // @ts-ignore
    this.logobuffer = document.getElementById('imagepreview').src;
    // console.log("this.logobuffer", this.logobuffer);

    if (!this.displaytype) {
      this.ts.info("Select display type");
      return;
    }
    if (!this.authMethod) {
      this.ts.info("Select authorization method");
      return;
    }

    if (this.authMethod === 'otp') {
      if (!this.authValidation) {
        this.ts.info("Select validation method");
        return;
      }
    }

    this.spinner.show();
    var styleobj = { 'height': this.height, 'width': this.width };


    var setdata;

    const formData: FormData = new FormData();
    if (this.file != undefined) {
      formData.append('file', this.file);
      setdata = {
        '_id': this.id,
        // 'description': this.desc,
        'webapgeurl': this.webpageurl,
        'style': styleobj,
        "displaytype": this.displaytype,
        "authomethod": this.authMethod,
        "validationmethod": this.authValidation,
        "parameter": this.profileParameter,
        "databaseURL": this.databaseURL,
        "host": this.host,
        "contacts": this.contacts,
        "label": this.label,
        "desc": this.desc,
        "updateddate": new Date(),
        "verifiername": this.verifiername,
        "verifiergrade": this.verifiergrade,
        "verification": this.verification,
        "has_node": this.hasnode,
        "channelName": this.channelname,

        // added account_setting 

        "account_setting": {
          "normal_details": this.normal_details || "",
          "value": this.detail_value
        },
      }


    } else {
      setdata = {
        'filename': this.logobuffer,
        // 'description': this.desc,
        '_id': this.id,
        'webapgeurl': this.webpageurl,
        'style': styleobj,
        "displaytype": this.displaytype,
        "authomethod": this.authMethod,
        "validationmethod": this.authValidation,
        "parameter": this.profileParameter,
        "databaseURL": this.databaseURL,
        "host": this.host,
        "contacts": this.contacts,
        "label": this.label,
        "desc": this.desc,
        "updateddate": new Date(),
        "verifiername": this.verifiername,
        "verifiergrade": this.verifiergrade,
        "verification": this.verification,
        "has_node": this.hasnode,
        "channelName": this.channelname,


        "account_setting": {
          "normal_details": this.normal_details || "",
          "value": this.detail_value
        },

      }


    }


    console.log("setdata", setdata);

    formData.append('data', JSON.stringify(setdata));
    // console.log("formData", formData);
    this.gs.updatesetting(formData).subscribe(data => { if (data['success']) { $("#file1").val(''); this.spinner.hide(); this.ts.success(data['msg']); this.getsingledata(); } else { this.spinner.hide(); this.ts.error(data['msg']); } }, error => { console.log(error); this.spinner.hide(); });


  }


  authvalid(v) {
    console.log(v);
    this.authValidation = v;
  }


  get_detail(d) {
    console.log(d)
    this.detail_value = d
  }

  onCheckboxChagen(event, value) {

    if (event.target.checked) {
      this.profileParameter.push(value);
    }
    if (!event.target.checked) {
      let index = this.profileParameter.indexOf(value);
      if (index > -1) {
        this.profileParameter.splice(index, 1);
      }
    }
    console.log(this.profileParameter);
  }


  geteformData() {

    // this.spinner.show();
    // console.log("this.efoemdata", this.efoemdata);
    // let data = {
    //   name: this.efoemdata
    // }

    this.gs.getpubliceformdetail(this.efoemdata).subscribe(data => {

      // console.log("data", data);

      if (data['status']) {
        this.eformlist = data['data'];
        // console.log("this.eformlist", this.eformlist);

        this.spinner.hide();
      } else {
        this.spinner.hide();
        // this.ts.error(data['message']);
      }
    }, error => {
      // console.log(error); this.spinner.hide(); this.ts.error('Somethinf went wrong');
    });
  }


  // getadminData() {
  //   // this.spinner.show();
  //   let data = {
  //     name: this.efoemdata
  //   }
  //   this.gs.getpubliceformdetail(data).subscribe(data => {
  //     console.log("data", data);

  //     if (data['status']) {
  //       this.eformlist = data['data'];
  //       console.log("this.eformlist", this.eformlist);

  //       this.spinner.hide();
  //     } else {
  //       this.spinner.hide();
  //       this.ts.error(data['message']);
  //     }
  //   }, error => {
  //     console.log(error); this.spinner.hide(); this.ts.error('Somethinf went wrong');
  //   });
  // }
  //   changestatus(val){
  // console.log("value",val);

  //   }



  addadmindata() {
    if (this.akcessid) {
      this.spinner.show();
      const data = {
        akcessId: this.akcessid,
        domainId: this.id
      }
      // console.log("data", data);

      this.gs.addadmin(data).subscribe(data => {
        // console.log("hihihhihih", data);
        if (data['status']) {
          $('#close').click();
          // this.getData();
          this.getsingledata();
          this.spinner.hide();
          this.ts.success(data['message']);
        } else {
          this.spinner.hide();
          this.ts.error(data['message']);
        }
      }, error => { console.log(error); this.spinner.hide(); });
    }
    else {
      this.ts.error('please enter domain name');
    }
  }



  changestatus() {
    let tmpst;
    if (this.verification == true) {
      console.log("zjczcjzn");
      tmpst = false;
    } else {
      tmpst = true;
      console.log("dsdfds");

    }
    this.verification = tmpst;
  }







}
