import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../app/models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('domainUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(data: any) {
         
                if(data){
                    localStorage.setItem('domainUser', JSON.stringify(data));
                    this.currentUserSubject.next(data);
                    this.router.navigate(['dashboard']);
                }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('domainUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['login']);
    }
}