import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralServiceService } from 'src/app/general-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  tdomain:number = 0;
  constructor(private gs : GeneralServiceService, private spinner: NgxSpinnerService,private toastr: ToastrService) {
    this.spinner.hide();
   }

  ngOnInit() {
    this.domain();
  }
  domain(){
    this.spinner.show();
    if(this.domain){
        this.gs.totaldomain().subscribe(data => { 
          console.log(data); 
            if(data['status']) {   
            this.toastr.success(data['message']); 
            this.tdomain = data['domain'];
            } else {
              this.spinner.hide(); 
              this.toastr.info(data['message']);
             }}, error=>{ console.log(error); this.spinner.hide();
               this.toastr.error('Something went wrong'); });
    }
  }
}
