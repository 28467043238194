import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { GeneralServiceService } from 'src/app/general-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  qrcodeData: string = "string";
  uuid: string;
  id;
  passcode: string;
  scanstatus: boolean = false;
  imageUrl: string = environment.imgurl;
  constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, private akcessService: GeneralServiceService, private auth: AuthenticationService) {

  }

  ngOnInit() {
    this.signInWithAkcess()
  }

  signInWithAkcess() {
    this.akcessService.getQrData().subscribe(data => {
      if (data['status'] === 1) {
        this.uuid = data['data'].webUUID;
        let n = JSON.stringify(data['data']);

        this.qrcodeData = n;
        this.calllogintimer();
        this.akcessService.getToken().subscribe(data => {
          console.log("data.token", data.token);

          localStorage.setItem('ak-api-io-token', data.token);
        })
      }
    })
  }

  calllogintimer() {
    this.id = setInterval(() => { this.logincheckService(); }, 3000);
  }

  logincheckService() {
    const uuidData = { uuid: this.uuid };
    this.akcessService.loginstatus(uuidData).subscribe(data => {
      if (data['status'] === 1) {
        clearInterval(this.id);
        this.scanstatus = true;
      }
    })
  }

  closepopup() {
    clearInterval(this.id);
  }


  clickakcess() {
    this.calllogintimer();
  }

  //   checkLoginData(data) {
  //     this.spinner.show();
  //     this.akcessService.addUser(data).subscribe(data => { if(data['success'] === 'true'){ 
  //     this.toastr.success('Login Successfully');
  //  //   this.auth.login(data['userRecord'],data['token'],data['_id']); 
  //     } else { console.log('something went wrong'); }
  //     },error => { console.log(error); } )
  // } 

  clickpasscode() {
    if (this.passcode) {
      this.spinner.show();
      const data = { passCode: this.passcode, uuid: this.uuid }
      this.akcessService.partyLogin(data).subscribe(data => {
        if (data['status'] === 1) {
          this.auth.login(data['data'])
        } else {
          this.spinner.hide();
          this.toastr.error(data['message']);
        }
      })
    } else {
      this.spinner.hide();
      this.toastr.info('Enter passCode');
    }
  }

  

}
