import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralServiceService } from '../general-service.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  public id: String = ""
  statistic_data:Object 
  constructor( private route: ActivatedRoute , private gs: GeneralServiceService ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log( 'ID statistics -------- ' , this.id)

    this.gs.domain_static(this.id).subscribe( data => this.statistic_data = data  )

  }
}
