import { Component, OnInit } from '@angular/core';
import { GeneralServiceService } from 'src/app/general-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  logslist: any = [];
  logdata: any = [];
  domain_list: any = []
  constructor(private gs: GeneralServiceService, private ts: ToastrService, private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.getDomainList();
  }


  getDomainList(page = 1, limit = 10) {
    // console.log('Log component !!! ')
    // this.spinner.show();
    // this.gs.domainlist().subscribe(data => {
    //   // console.log('data coming!! ')
    //   console.log(data);


    //   if (data['status']) {
    //     this.logslist = data['domain'];
    //     this.spinner.hide();
    //   } else {
    //     this.spinner.hide();
    //     this.ts.error(data['message']);
    //   }
    // }, error => {
    //   console.log(error);
    //   this.spinner.hide();
    //   this.ts.error('Something went wrong');
    // });

    this.spinner.show();

    this.gs.getdomaindetailactive(page, limit).subscribe(data => {
      console.log(data)
      if (data['status']) {
        this.domain_list = data['data'].data;
        // console.log(this.domain_list)

        this.spinner.hide();
      } else { this.spinner.hide(); this.ts.error(data['message']); }
    }, error => { console.log(error); this.spinner.hide(); this.ts.error('Somethinf went wrong'); });
  }

  changedomain(e) {
    // console.log(e.target.value);
    this.getLogsData(e.target.value);
  }

  getLogsData(api) {
    // this.logdata = [];
    this.spinner.show();

    // console.log(api )
    // return
    this.gs.getlogs(api).subscribe(data => {

      if (data['status']) {
        this.logdata = data['data'];
        console.log(this.logdata)
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.ts.error(data['message']);
      }
    }, error => {
      this.spinner.hide();
      this.ts.error('Something went wrong');
    });
  }


}
