import { Component, OnInit } from '@angular/core';
import  {AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './profilemenu.component.html',
  styleUrls: ['./profilemenu.component.css']
})
export class ProfilemenuComponent implements OnInit {
  headeremail:string;
  constructor(private as:AuthenticationService) { }

  ngOnInit() {
    let data = JSON.parse(localStorage.getItem('domainUser'));
   this.headeremail =  data.userEmail;
  }
  logout(){
    this.as.logout();
  }

}
