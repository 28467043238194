import { Component, OnInit } from "@angular/core";
import { GeneralServiceService } from "src/app/general-service.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: "app-domainlist",
  templateUrl: "./domainlist.component.html",
  styleUrls: ["./domainlist.component.css"],
})
export class DomainlistComponent implements OnInit {
  domain: string;
  companyname: string;
  domainlist: any = [];
  limit: number;
  page: number;
  total: number;
  has_node: boolean;
  has_requested: boolean;
  channelname: string;
  blockchainnodename: string;
  constructor(
    private gs: GeneralServiceService,
    private ts: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getData();
  }

  adddomaindata() {
    if (this.domain) {
      this.spinner.show();
      //change the logo
      let data = {
        has_node: this.has_node,
        domain: this.domain,
        companyname: this.companyname,
        has_requested: this.has_requested,
        channelname: this.channelname,
        blockchainnodename: this.blockchainnodename,
      };
      console.log("data", data);

      this.gs.addDomain(data).subscribe(
        (data) => {
          console.log(data);
          if (data["status"]) {
            $("#close").click();
            this.getData();
            this.ts.success(data["message"]);
          } else {
            this.spinner.hide();
            this.ts.error(data["message"]);
          }
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.ts.error("Something went wrong");
        }
      );
    } else {
      this.ts.error("please enter domain name");
    }
  }

  getData(page = 1, limit = 10) {
    this.spinner.show();
    this.gs.getdomaindetail(page, limit).subscribe(
      (data) => {
        if (data["status"]) {
          this.domainlist = data["data"].data;
          this.limit = data["data"].limit;
          this.page = data["data"].page;
          this.total = data["data"].total;
          console.log("this.domainlist", this.domainlist);

          console.log(" this.limit ", this.limit);
          console.log("this.page  ", this.page);

          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.ts.info(data["message"]);
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.ts.error("Somethinf went wrong");
      }
    );
  }

  deletedomain(val) {
    this.spinner.show();
    // const data = { "id": val };
    this.gs.deletedomain(val).subscribe(
      (data) => {
        if (data["status"]) {
          this.getData();
          this.ts.success(data["message"]);
        } else {
          this.spinner.hide();
          this.ts.error(data["message"]);
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.ts.error("Somethinf went wrong");
      }
    );
  }

  seperatechenal(val) {
    if (val == true) {
      (this.has_node = false), (this.has_requested = val);
      let chenaldata = this.domain.split("." || "@");
      this.channelname = chenaldata[0] + "private";
    } else {
      (this.has_node = false), (this.has_requested = val);
      this.channelname = "";
      this.blockchainnodename = "";
    }
  }
}
