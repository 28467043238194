export const environment = {
  production: true,
  //url : 'https://vps260154.vps.ovh.ca:4000/',
  // url : 'https://api.akcess.io:3300/',
  url: 'https://api.akcess.dev/',
  //urlAkcess: 'https://ak-api.akcess.dev/api/v1/',
  // urlAkcess : 'https://mobile.akcess.io/',
  urlAkcess: 'https://mobile.akcess.dev:3000/',
  imgurl: 'https://api.akcess.dev/assets/img/',
  imgserverurl: "https://api.akcess.io/",
  urllogin: 'https://ak-api.akcess.dev/',
  // imgserverurl : "https://api.akcess.dev:3100/",
  // domainname: 'api.akcess.io',
  // apiKey: "574d4f7cf0a9ab9c728cf11ddb6cc826" //io
  domainname: 'api.akcess.dev',
  apiKey: "a84d5e050b65a0b36646f2a38c1390f6" //dev
  
  };
