import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../app/models/user';
const domainurl = environment.domainname;
@Injectable({
  providedIn: 'root'
})

export class GeneralServiceService {
  akcessId: string = "";
  httpOptions: any;
  constructor(private http: HttpClient) {

    //    this.httpOptions = {
    //     headers: new HttpHeaders({
    //         'Content-Type' : 'application/json',
    //     })
    // }
  }

  getuserdata() {
    var data = JSON.parse(localStorage.getItem('domainUser'));
    this.akcessId = data['akcessId'];
  }

  addUser(user: User): Observable<User> {
    return this.http.post<User>(environment.url + "api/authenticate", user);
  }
  addDomain(domainname) {
    this.getuserdata();
    console.log("domainname",domainname);
    
    // let domainurl = environment.url;
    const data = {
      "akcessId": this.akcessId,
      'dname': domainname,
      'usedomain': domainurl,
      'status': 'Inactive',
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    console.log("data22222222", data);

     return this.http.post<any>(environment.url + "api/adddomain", data);
  }

  addadmin(admindata) {
    this.getuserdata();
    console.log("apilog", admindata);
    const data = {
      admindata: admindata,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    }
    return this.http.post<any>(environment.url + "api/addadmin", data);
  }
  totaldomain() {
    this.getuserdata();
    const data = {
      "akcessId": this.akcessId,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.url + "api/totaldomain", data);
  }

  domainlist() {
    this.getuserdata();
    const data = {
      "akcessId": this.akcessId,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    // console.log(data)
    return this.http.post<any>(environment.url + "api/getdomainlist", data);
  }


  getlogs(apikey) {
    this.getuserdata();
    const data = {
      api: apikey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.url + "api/getlogs", data);
  }


  getdomaindetail(page, limit) {
    this.getuserdata();
    const data = {
      "akcessId": this.akcessId,
      page: page,
      limit: limit,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.url + "api/getdomaindata", data);
  }

  getdomaindetailactive(page, limit) {
    this.getuserdata();
    const data = {
      "akcessId": this.akcessId,
      page: page,
      limit: limit,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.url + "api/getdomaindataactive", data);
  }

  getpubliceformdetail(name) {
    this.getuserdata();
    const data = {
      "name": name,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    console.log("data5555555555", data);

    return this.http.post<any>(environment.url + "api/getpubliceform", data);
  }


  deletedomain(val) {
    const data = {

      id: val,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.url + "api/deletedomain", data);
  }

  getToken() {
    const data = { api: environment.apiKey };
    console.log("data", data);

    return this.http.post<any>(environment.urllogin + 'api/v1/getToken', data);
  }




  getQrData() {
    const data = {
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.urlAkcess + "get3rdPartyLoginUUID", data);
  }
  // getQrData(){
  //   return this.http.post<any>(environment.urlAkcess + "get3rdPartyLoginUUID",'');
  // }
  getfieldList() {
    return this.http.get<any>(environment.url + "api/getprofileData");
  }
  loginstatus(uuid) {
    return this.http.post<any>(environment.urlAkcess + "poll3rdPartyLoginStatus", uuid);
  }
  partyLogin(data) {
    data.api = environment.apiKey;

    return this.http.post<any>(environment.urlAkcess + "do3rdPartyLogin", data);
  }
  updatesetting(data) {
    // console.log("111111111111111111111111", data);
    // const updatesettingdata = {
    //   data: data,
    //   api: environment.apiKey,
    //   AKcessToken: localStorage.getItem('ak-api-io-token')
    // };
    return this.http.post<any>(environment.url + "api/updateprofilesetting", data);
  }

  singledomaindata(data) {
    const singledomaindata = {
      id: data,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    return this.http.post<any>(environment.url + "api/getsingledomaindata", singledomaindata);
  }


  domain_static(id){
    const domainstatic = {
      id: id,
      api: environment.apiKey,
      AKcessToken: localStorage.getItem('ak-api-io-token')
    };
    // console.log( this.domain_static)
    return this.http.post<any>(environment.url + "api/getdomainstatistic", domainstatic );

  }


  listAdminData() {
    this.getuserdata();
    const data = { "akcessId": this.akcessId };
    return this.http.post<any>(environment.url + "api/getadmindata", data);
  }


  inviteAdmin() {
    this.getuserdata();
    const data = { "akcessId": this.akcessId };
    return this.http.post<any>(environment.url + "api/invite", data);
  }

}
