import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DomainlistComponent } from './domainlist/domainlist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogsComponent } from './logs/logs.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { DownloadComponent } from './download/download.component';
import { SettingComponent } from './setting/setting.component';
import { AuthGuard } from  '../app/guards/auth.guard';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard] },
  { path: 'domain', component: DomainlistComponent , canActivate: [AuthGuard] },
  { path: 'logs', component: LogsComponent , canActivate: [AuthGuard] },
  { path: 'stat/:id', component: StatisticsComponent , canActivate: [AuthGuard] },
  { path: 'download', component: DownloadComponent , canActivate: [AuthGuard] },
  { path: 'setting/:id', component: SettingComponent, canActivate : [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
